// @flow
import React from 'react'
import styles from './button-play.module.scss'
import Spinner from '@components/common/spinner'

interface Props {
  className?: string
  size?: string | number
  onClick: () => void
  disabled?: boolean
  isPlaying: boolean
  isLoading: boolean
}

const ButtonPlay = ({
  className,
  size,
  onClick,
  disabled,
  isPlaying,
  isLoading,
}: Props) => (
  <button
    aria-label="Play"
    type="button"
    className={`${className} ${styles.btn} ${isLoading ? styles.loading : ''}`}
    onClick={onClick}
    disabled={disabled}
  >
    <style jsx>
      {`
        button,
        svg {
          width: ${size};
          height: ${size};
        }
      `}
    </style>
    {!isPlaying ? (
      <svg
        className={isPlaying ? styles.pause : styles.play}
        viewBox="0 0 100 100"
      >
        <polygon points="74,50 42,66 42,34" className={styles.fill} />
      </svg>
    ) : (
      <>
        {isLoading ? (
          <Spinner size={size} />
        ) : (
          <svg
            className={isPlaying ? styles.pause : styles.play}
            viewBox="0 0 100 100"
          >
            <g>
              <rect
                x="31.5"
                y="31.5"
                width="12"
                height="37"
                className={styles.fill}
              />
              <rect
                x="56.5"
                y="31.5"
                width="12"
                height="37"
                className={styles.fill}
              />
            </g>
          </svg>
        )}
      </>
    )}
  </button>
)

ButtonPlay.defaultProps = {
  className: '',
  size: '2.25rem',
  disabled: false,
}

export default ButtonPlay
