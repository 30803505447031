// @flow
import * as React from 'react'

import Footer from './footer'
import Navbar from './navbar'
import styles from './layout.module.scss'
import Breadcrumb from '@components/app/breadcrumb'
import Messages from '@components/app/messages'
import Player from '@components/app/player'


interface Props {
  plain?: boolean
  inverse?: boolean
  children: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  breadcrumbs?: Array<any>
}

const Layout = ({ plain, inverse, children, breadcrumbs }: Props) => (
  <>
    <Messages />
    <Navbar plain={plain} inverse={inverse} />
    <div className={styles.main}>{children}</div>
    <Breadcrumb items={breadcrumbs} />
    <Footer />
    <Player />
  </>
)

Layout.defaultProps = {
  plain: false,
  inverse: false,
  breadcrumbs: [],
}

export default Layout
