import { useState } from 'react'
import { createContainer } from 'unstated-next'

export const CART_SESSION_KEY = 'cart'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveCartSession = (data: any) => {
  localStorage.setItem(CART_SESSION_KEY, JSON.stringify(data))
}
export const getCartSession = () =>
  JSON.parse(localStorage.getItem(CART_SESSION_KEY) || '[]')
export const clearCartSession = () => localStorage.removeItem(CART_SESSION_KEY)

const isServer = typeof window === 'undefined'

interface ItemType {
  type?: string
  data: {
    id: string
    title: string
    // eslint-disable-next-line camelcase
    coverart_crop_url: string
  }
  price: number
  message: string
  category: string
}

interface Props {
  items: Array<ItemType>
}

const initialState = {
  items: isServer ? [] : getCartSession(),
}

const useCart = (props: Props = initialState) => {
  const [ items, setItems ] = useState(props.items)

  const addItem = (payload: ItemType) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = items.find((item: any) => item.data.id === payload.data.id)
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      items.map((item: any) =>
        item.data.id === payload.data.id ? payload : item,
      )
      : [ ...items, payload ]
    saveCartSession(result)
    setItems(result)
  }

  const setItem = (payload: ItemType) => {
    saveCartSession([ payload ])
    setItems([ payload ])
  }

  const clear = () => {
    clearCartSession()
    setItems([])
  }

  return {
    items,
    addItem,
    setItem,
    clear,
  }
}

const Cart = createContainer(useCart)

export default Cart
