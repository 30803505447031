import { ReactNode, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { IntlProvider } from 'react-intl'
import Cookies from 'universal-cookie'
import enMessage from '../../../lang/en.json'
import jaMessage from '../../../lang/ja.json'
import Cart from './cart'
import Messages from './messages'
import Player from './player'
import Uploads from './uploads'

type Props = {
  children: ReactNode
}

export default function Providers({ children }: Props) {
  const router = useRouter()
  const messages = useMemo(() => router.locale === 'ja' ? jaMessage : enMessage, [ router.locale ])

  useEffect(() => {
    const cookies = new Cookies()
    cookies.set('NEXT_LOCALE', router.locale)
  }, [ router.locale ])

  return (
    <IntlProvider locale={router.locale} messages={messages} defaultLocale={router.defaultLocale}>
      <Messages.Provider>
        <Uploads.Provider>
          <Player.Provider>
            <Cart.Provider>
              {children}
            </Cart.Provider>
          </Player.Provider>
        </Uploads.Provider>
      </Messages.Provider>
    </IntlProvider>
  )
}
