import React, { useEffect } from 'react'
import { Analytics } from '@vercel/analytics/react'
import { AppProps } from 'next/app'
import Error from 'next/error'
import Head from 'next/head'
import Router from 'next/router'
import Providers from '@/data/contexts/providers'
import CookieConsent from '@/components/app/cookie-consent'
import Layout from '@/components/layouts/layout'
import '../../styles/icons.scss'
import '../../styles/style.scss'
import ErrorPage from './_error'
import Bugsnag from '@/utils/bugsnag'
import * as gtag from '@/utils/ga'
import configureLoadingProgressBar from '@/utils/nprogress'

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

function SoundonliveApp({ Component, pageProps = {}}: AppProps) {

  useEffect(() => {
    configureLoadingProgressBar()
    Router.events.on('routeChangeComplete', (url) => gtag.pageview(url))
    return () => {
      Router.events.off('routeChangeComplete', (url) => gtag.pageview(url))
    }
  }, [])

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
      </Head>
      <Providers>
        <Layout {...pageProps}>
          {
            !pageProps.ErrorCode ? (
              <Component {...pageProps} />
            ) : (
              <Error statusCode={pageProps.ErrorCode} message={pageProps.ErrorMessage} />
            )
          }
        </Layout>
        <CookieConsent />
      </Providers>
      <Analytics />
    </ErrorBoundary>
  )
}

export default SoundonliveApp
