import * as React from 'react'
import PopperJS from 'popper.js'
import ReactDOM from 'react-dom'

interface Props {
  id: string
  /**
   * This is the DOM element, or a function that returns the DOM element,
   * that may be used to set the position of the popover.
   * The return value will passed as the reference object of the Popper
   * instance.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  anchorEl: any

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  container?: any
  /**
   * Popper render function or node.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
  /**
   * Always keep the children in the DOM.
   * This property can be useful in SEO situation or
   * when you want to maximize the responsiveness of the Popper.
   */
  keepMounted: boolean
  /* eslint-disable */
  /**
   * Popper.js is based on a "plugin-like" architecture,
   * most of its features are fully encapsulated "modifiers".
   *
   * A modifier is a function that is called each time Popper.js needs to
   * compute the position of the popper.
   * For this reason, modifiers should be very performant to avoid bottlenecks.
   * To learn how to create a modifier, [read the modifiers documentation](https://github.com/FezVrasta/popper.js/blob/master/docs/_includes/popper-documentation.md#modifiers--object).
   */
  /* eslint-enable */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modifiers: any
  /**
   * If `true`, the popper is visible.
   */
  open: boolean
  /**
   * Popper placement.
   */
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  /**
   * Options provided to the [`popper.js`](https://github.com/FezVrasta/popper.js) instance.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any
  /**
   * Help supporting a react-transition-group/Transition component.
   */
  transition?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getAnchorEl(anchorEl: any) {
  return typeof anchorEl === 'function' ? anchorEl() : anchorEl
}

interface State {
  exited: boolean
  placement: string
}

/**
 * Poppers rely on the 3rd party library
 * [Popper.js](https://github.com/FezVrasta/popper.js) for positioning.
 */
class Popper extends React.Component<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  public static defaultProps: Partial<Props> = {
    container: null,
    placement: 'bottom',
    transition: false,
    options: {},
  }

  popper = null

  constructor(props) {
    super(props)
    this.state = {
      exited: !props.open,
      placement: '',
    }
  }

  componentDidUpdate(prevProps) {
    const {
      open,
      transition,
      anchorEl,
      options,
      modifiers,
      placement,
    } = this.props

    if (prevProps.open !== open && !open && !transition) {
      // Otherwise handleExited will call this.
      this.handleClose()
    }

    // Let's update the popper position.
    if (
      prevProps.open !== open ||
      prevProps.anchorEl !== anchorEl ||
      prevProps.options !== options ||
      prevProps.modifiers !== modifiers ||
      prevProps.placement !== placement
    ) {
      this.handleOpen()
    }
  }

  componentWillUnmount() {
    this.handleClose()
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.open) {
      return {
        exited: false,
      }
    }

    if (!nextProps.transition) {
      // Otherwise let handleExited take care of marking exited.
      return {
        exited: true,
      }
    }

    return null
  }

  handleOpen = () => {
    const { anchorEl, modifiers, open, placement, options = {}} = this.props

    // eslint-disable-next-line react/no-find-dom-node
    const popperNode = ReactDOM.findDOMNode(this) as Element

    if (!popperNode || !anchorEl || !open) {
      return
    }

    if (this.popper) {
      this.popper.destroy()
      this.popper = null
    }

    this.popper = new PopperJS(getAnchorEl(anchorEl), popperNode, {
      placement,
      ...options,
      modifiers: {
        preventOverflow: {
          boundariesElement: 'window',
        },
        ...modifiers,
        ...options.modifiers,
      },
      // We could have been using a custom modifier like react-popper is doing.
      // But it seems this is the best public API for this use case.
      onCreate: this.handlePopperUpdate,
      onUpdate: this.handlePopperUpdate,
    })
  }

  handlePopperUpdate = (data) => {
    const { placement } = this.state
    if (data.placement !== placement) {
      this.setState({
        placement: data.placement,
      })
    }
  }

  handleExited = () => {
    this.setState({ exited: true })
    this.handleClose()
  }

  handleClose = () => {
    if (!this.popper) {
      return
    }

    this.popper.destroy()
    this.popper = null
  }

  render() {
    const {
      container,
      children,
      keepMounted,
      open,
      transition,
      ...other
    } = this.props
    const { exited, placement } = this.state

    if (!keepMounted && !open && (!transition || exited)) {
      return null
    }

    const childProps = {
      placement,
      TransitionProps: {},
    }

    if (transition) {
      childProps.TransitionProps = {
        in: open,
        onExited: this.handleExited,
      }
    }

    return this.handleOpen ? (
      ReactDOM.createPortal(children, container || document.body)
    ) : (
      <div
        role="tooltip"
        style={{
          // Prevents scroll issue, waiting for Popper.js to add this style once initiated.
          position: 'absolute',
        }}
        className="z-popper"
        {...other}
      >
        {typeof children === 'function' ? children(childProps) : children}
      </div>
    )
  }
}

export default Popper
