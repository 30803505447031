// @flow
import * as React from 'react'
import classnames from 'classnames'
import Link from 'next/link'
import styles from './button.module.scss'

interface Props {
  href: string
  as?: string
  children: React.ReactNode
  className?: string
}

const LinkButton = ({ href, children, className, ...props }: Props) => {
  const classes = className.split(' ').map((name) => styles[name] || name)
  return (
    // eslint-disable-next-line react/button-has-type
    <Link href={href} {...props} className={classnames(styles.btn, classes)}>
      {children}
    </Link>
  )
}

LinkButton.defaultProps = {
  as: '',
  className: '',
}

export default LinkButton
