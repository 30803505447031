import React, { useCallback } from 'react'
import PlayerCtx from '@data/contexts/player'
import { timenize } from '@utils/string'
import { AnimatePresence, motion } from 'framer-motion'
import useSWR from 'swr'
import Waveform from './waveform'
import styles from './waveform.module.scss'

const fetchWave = async (src: string) => {
  if (!src) {
    return null
  }
  const result = await fetch(src).then((r) => r.json())
  return result ? result.peaks : null
}

interface Props {
  id?: string
  src: string
  duration: number
  height?: number
  static?: boolean
  hideTime?: boolean
  className?: string
  outerColor?: string
}

const WaveformContainer = ({
  id = '',
  height = 40,
  hideTime = false,
  className = '',
  outerColor = 'rgba(37, 43, 51, .4)',
  duration,
  src,
  ...props
}: Props) => {
  const { data: peaks, error, isLoading } = useSWR(src, () => fetchWave(src), {
    revalidateIfStale: false,
  })

  const {
    isPlaying,
    nowPlaying,
    currentTime,
    setSeekTime,
  } = PlayerCtx.useContainer()

  const isThis = nowPlaying.track.id === id
  const isPlayingThis = isThis && isPlaying
  const position = isThis ? currentTime / duration : 0

  const onWaveClick = useCallback((pers) => {
    if (!isThis || props.static) {
      return
    }
    setSeekTime(duration * pers)
  }, [ props.static, duration, isThis, setSeekTime ])

  if (error && !src) {
    return null
  }

  return (
    <div className="waveform ps-relative">
      <style jsx>
        {`
          .waveform {
            height: ${height};
          }
        `}
      </style>
      <AnimatePresence>
        {!!peaks && !isLoading && (
          <motion.div
            className={styles.canvas}
            initial={{ scaleY: 0 }}
            viewport={{ once: true }}
            whileInView={{ scaleY: 1 }}
            transition={{
              type: 'spring',
              friction: 10,
              precision: 0.3,
            }}
          >
            <Waveform
              {...props}
              className={className}
              outerColor={outerColor}
              src={src}
              peaks={peaks}
              height={height}
              // eslint-disable-next-line react/destructuring-assignment
              static={!isPlayingThis || props.static}
              position={position}
              onClick={onWaveClick}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {!hideTime && (
        <div className={styles.time}>
          {isThis && <span>{timenize(currentTime)} / </span>}
          {timenize(duration)}
        </div>
      )}
    </div>
  )
}

export default WaveformContainer
