import React, { useEffect } from 'react'
// import dynamic from 'next/dynamic'
import BtnPlay from '@components/app/button-play'
import Waveform from '@components/app/waveform'
import PlayerCtx from '@data/contexts/player'
import classnames from 'classnames'
import Image from 'next/legacy/image'
import Link from 'next/link'
import Audio from '../audio'
import Playlist from './playlist'
import styles from './player.module.scss'

// const Audio = dynamic(() => import('../audio'), { ssr: false })

const Player = () => {
  const {
    tracks,
    isLoading,
    isPlaying,
    nowPlaying,
    setLoading,
    setCurrentTime,
    seekTime,
    setSeekTime,
    play,
    pause,
    backward,
    forward,
    repeat,
    repeat1,
    setRepeat,
    setRepeat1,
  } = PlayerCtx.useContainer()
  // const [ isLoad, setLoad ] = useState(false)
  // const [ disable, setDisable ] = useState(false)
  const disable = tracks.list.length < 2
  // useEffect(() => setLoad(true))

  const onPlaylistClick = (index: number) => play(tracks.list[index])

  const repeatOnClick = () => {
    if (repeat) {
      setRepeat1(true)
      return
    }
    if (repeat1) {
      setRepeat(false)
      return
    }
    setRepeat(true)
  }

  const onPlayClick = () => {
    if (isPlaying) {
      pause()
      return
    }
    play()
  }

  const onEnded = () => {
    pause()
    setSeekTime(0)
    if (repeat1) {
      play()
      return
    }
    forward()
  }

  const onSpaceKeydown = (e) => {
    if (e.which !== 32) {
      return
    }
    if (
      /button|input|a|select|textarea/.test(document.activeElement.tagName.toLowerCase())
      || /draft/.test(document.activeElement.className.toLowerCase())
    ) {
      return
    }
    e.preventDefault()
    onPlayClick()
  }

  useEffect(() => {
    window.addEventListener('keydown', onSpaceKeydown)
    return () => {
      window.removeEventListener('keydown', onSpaceKeydown)
    }
  }, [ isPlaying ])

  return (
    <div
      className={classnames(styles.player, {
        [styles.show]: tracks.list.length > 0,
      })}
    >
      <div className="px-md-3 px-1">
        <div className={styles.container}>
          <Playlist
            tracks={tracks}
            current={nowPlaying.index}
            onClick={onPlaylistClick}
          />
          <div className={styles.vr} />
          <div className="d-none d-md-flex align-items-center">
            <button
              type="button"
              className={`${styles.btn} ${styles.backward}`}
              disabled={disable}
              onClick={backward}
            >
              <Image
                src="/static/images/icons/backward.svg"
                alt="Backward"
                width={28}
                height={10}
              />
            </button>
            <BtnPlay
              size="2.25rem"
              className={styles.play}
              isLoading={isLoading}
              isPlaying={isPlaying}
              onClick={onPlayClick}
            />
            <button
              type="button"
              className={`${styles.btn} ${styles.forward}`}
              disabled={disable}
              onClick={forward}
            >
              <Image
                src="/static/images/icons/forward.svg"
                alt="Forward"
                width={28}
                height={10}
              />
            </button>
            <div className={styles.vr} />
            <button
              type="button"
              className={classnames(styles.btn, styles.repeat, {
                [styles.active]: repeat || repeat1,
              })}
              onClick={repeatOnClick}
              // disabled={disable}
            >
              {repeat1 ? (
                <img src="/static/images/icons/repeat1.svg" alt="Repeat" />
              ) : (
                <img src="/static/images/icons/repeat.svg" alt="Repeat" />
              )}
            </button>
            <div className={styles.vr} />
          </div>
          {nowPlaying.track.id && (
            <Link
              href={`/sound/${nowPlaying.track.id}/${nowPlaying.track.slug}`}
              className={`${styles.playing} alt`}
            >
              <div className="flex0">
                <img
                  className={styles.coverart}
                  src={nowPlaying.track.coverart || '/static/images/cover.png'}
                  alt={nowPlaying.track.title}
                />
              </div>
              <div className="flex1 ov-hidden">
                <div className="tx-16 tx-w500 tx-black tx-ellipsis">
                  {nowPlaying.track.title}
                </div>
                <div className="tx-12 tx-g700 tx-ellipsis">
                  by {nowPlaying.track.user}
                </div>
              </div>
            </Link>
          )}
          <div className="flex0 d-none d-md-block">
            <Waveform
              id={nowPlaying.track.id}
              src={nowPlaying.track.waveformUrl}
              className={styles.waveform}
              duration={nowPlaying.track.duration}
              static={false}
            />
          </div>
          <div className="d-md-none flex0 px-3">
            <BtnPlay
              size="2.25rem"
              className={styles.play}
              isLoading={isLoading}
              isPlaying={isPlaying}
              onClick={onPlayClick}
            />
          </div>
          {/* claps */}
          {/* comments */}
        </div>
      </div>
      <Audio
        mp3Url={nowPlaying.track.mp3Url}
        hlsUrl={nowPlaying.track.hlsUrl}
        isPlaying={isPlaying}
        seekTime={seekTime}
        limit={nowPlaying.track.limit}
        onLoadUpdate={setLoading}
        onTimeUpdate={setCurrentTime}
        onEnded={onEnded}
      />
    </div>
  )
}

Player.defaultProps = {}

export default Player
