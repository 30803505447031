import { useState, useRef } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { createContainer } from 'unstated-next'
import { AlbumProps } from '../../../types/album'
import { SoundProps } from '../../../types/sound'

import { PUBLICITY_PUBLIC } from '../../utils/constants'
import { postTranscode } from '../api/transcode'
import { getUploadSoundUrl, uploadFile } from '../api/upload'
import MessagesCtx from './messages'

export const STATUS_UPLOADING = 'UPLOADING'
export const STATUS_TRANSCODING = 'TRANSCODING'
export const STATUS_READY = 'READY'
export const STATUS_ERROR = 'ERROR'

const messages = defineMessages({
  uploaded: {
    id: 'upload.message.uploaded',
    defaultMessage: '{name} has been uploaded. Now prepare to transcode...',
  },
  error: {
    id: 'upload.message.error',
    defaultMessage: 'Error occurred while transcoding {name}. Please remove sound and upload it again.',
  },
})

const albumData: AlbumProps = {
  title: '',
  description: '',
  coverartURL: '',
  status: PUBLICITY_PUBLIC,
  genre: '',
  sales: false,
  price: 1000,
  nameyourprice: true,
  tags: [],
}

const defaultSound: SoundProps = {
  id: '',
  title: '',
  description: '',
  coverartURL: '',
  status: PUBLICITY_PUBLIC,
  genre: '',
  tags: [],
  sales: false,
  price: 100,
  nameyourprice: true,
  progress: 0.1,
  uploading: STATUS_UPLOADING,
  error: false,
  metadata: { duration: 0 },
  sampling: true,
  limit: false,
  limitFrom: 0,
  limitTo: 0,
  license: 1,
  ccType: 1,
  uploader: null,
}

interface Props {
  isUploading: boolean
  isAlbum: boolean
  isReady: boolean
  album: AlbumProps
  sounds: SoundProps[]
}

const initialState = {
  isUploading: false,
  isAlbum: true,
  isReady: false,
  album: albumData,
  sounds: [],
}

const useUploads = (props: Props = initialState) => {
  const intl = useIntl()
  const [ isAlbum, setIsAlbum ] = useState(props.isAlbum)
  const [ isUploading, setUploading ] = useState(props.isUploading)
  const [ isReady, setReady ] = useState(props.isReady)
  const [ album, setAlbum ] = useState(props.album)
  const [ sounds, setSounds ] = useState(props.sounds)
  const albumRef = useRef(props.album)
  const soundsRef = useRef(props.sounds)

  const { addMessage } = MessagesCtx.useContainer()

  const clearUploads = () => {
    setIsAlbum(initialState.isAlbum)
    setUploading(initialState.isUploading)
    setReady(initialState.isReady)
    setAlbum(initialState.album)
    albumRef.current = initialState.album
    setSounds(initialState.sounds)
    soundsRef.current = initialState.sounds
  }

  const toggleAlbum = (payload: boolean) => {
    setIsAlbum(payload)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateAlbum = (payload: AlbumProps) => {
    albumRef.current = { ...albumRef.current, ...payload }
    setAlbum(albumRef.current)
  }

  const addSound = (sound: SoundProps) => {
    // eslint-disable-next-line no-console
    console.log('add Sound')
    soundsRef.current = [ ...soundsRef.current, sound ]
    // eslint-disable-next-line no-console
    console.log(soundsRef.current)
    setSounds(soundsRef.current)
  }

  const updateSound = (payload: SoundProps) => {
    // eslint-disable-next-line no-console
    soundsRef.current = soundsRef.current.map((sound) =>
      sound.id === payload.id ? { ...sound, ...payload } : sound,
    )
    // eslint-disable-next-line no-console
    console.log(soundsRef.current)
    setSounds(soundsRef.current)
  }

  const deleteSound = (payload: SoundProps) => {
    // eslint-disable-next-line no-console
    console.log('Delete Sound')
    // abort upload
    soundsRef.current.find((sound) => sound.id === payload.id).uploader.abort()

    soundsRef.current = soundsRef.current.filter(
      (sound) => sound.id !== payload.id,
    )

    setSounds(soundsRef.current)
    setUploading(soundsRef.current.length > 0)
  }

  const onProgress = ({ id, loaded, total }) => {
    const progress = (loaded / total) * 100
    updateSound({ id, progress: isNaN(progress) ? 0 : progress })
  }

  const onComplete = async ({ id, name, key }) => {
    // transcoding
    const result = await postTranscode(key)
    if (result.error) {
      updateSound({
        id,
        uploading: STATUS_ERROR,
        error: true,
      })
      addMessage(intl.formatMessage(messages.error, { name }))
      return
    }

    const { transcodeId } = result
    updateSound({ id, transcodeId, fileName: name })
    addMessage(intl.formatMessage(messages.uploaded, { name }))
  }

  const onError = ({ id, name }) => {
    updateSound({
      id,
      progress: 100,
      uploading: STATUS_ERROR,
      error: true,
    })
    addMessage(intl.formatMessage(messages.error, { name }))
  }

  const uploadSound = async (payload) => {
    setUploading(true)
    // do upload
    const { url, params, id } = await getUploadSoundUrl(
      payload.name,
      payload.type,
    )
    const options = {
      id,
      name: payload.name,
      key: params.key,
    }
    const uploader = uploadFile(url, payload, params, options)

    const sound: SoundProps = {
      ...defaultSound,
      id,
      title: payload.name.split('.').slice(0, -1).join('.'),
      uploader,
    }

    addSound(sound)

    uploader.events.on('progress', onProgress)
    uploader.events.on('complete', onComplete)
    uploader.events.on('error', onError)
  }

  return {
    isAlbum,
    isReady,
    album,
    sounds,
    isUploading,
    clearUploads,
    toggleAlbum,
    updateAlbum,
    addSound,
    updateSound,
    deleteSound,
    uploadSound,
  }
}

const Uploads = createContainer(useUploads)

export default Uploads
