import { useState, useEffect, useRef, useCallback } from 'react'
import Router from 'next/router'
import { FormattedMessage } from 'react-intl'
import useAuth from '@/hooks/auth'
import UserMenu from './user-menu'
import styles from './navbar.module.scss'
import Avatar from '@components/app/avatar'
import LinkButton from '@components/common/button/link'
import { Popper } from '@components/common/popper'

const HeaderMenu = () => {
  const [ showMenu, setShowMenu ] = useState(false)
  const popperContainer = useRef()
  const anchorEl = useRef()
  const { currentUser: user, signout } = useAuth()

  const onWindowClick = useCallback((e) => {
    const el: HTMLDivElement = popperContainer.current
    if (el && el.contains(e.target)) {
      return
    }
    setShowMenu(false)
    document.removeEventListener('click', onWindowClick)
  }, [])

  const onClick = useCallback(() => {
    if (!showMenu) {
      document.addEventListener('click', onWindowClick)
    }
    setShowMenu(!showMenu)
  }, [ onWindowClick, showMenu ])

  useEffect(() => {
    const hideMenu = () => {
      setShowMenu(false)
      document.removeEventListener('click', onWindowClick)
    }
    Router.events.on('routeChangeStart', hideMenu)
    return () => {
      Router.events.off('routeChangeStart', hideMenu)
      document.removeEventListener('click', onWindowClick)
    }
  }, [ onWindowClick ])

  return (
    <div ref={popperContainer}>
      <button
        ref={anchorEl}
        type="button"
        className="btn-chromeless"
        onClick={onClick}
      >
        <Avatar src={user.user_image} alt={user.name} width="32" />
      </button>
      <Popper
        id="header-menu"
        open={showMenu}
        anchorEl={anchorEl.current}
        container={popperContainer.current}
        placement="bottom"
        options={{ positionFixed: true }}
      >
        <div className={`z-popper ${styles.menu}`}>
          <div className={`d-flex p-3 ${styles.profile}`}>
            <div className="flex0 mr-3">
              <figure className="m-0">
                <Avatar src={user.user_image} width="80" alt={user.name} />
              </figure>
            </div>
            <div className="flex1">
              <h4 className="mb-1">{user.name}</h4>
              <div className="tx-14 tx-g800 mb-3">@{user.username}</div>
              <LinkButton
                href={`/user/${user.username}`}
                className={`tx-12 ${styles.viewProfile}`}
              >
                <FormattedMessage
                  id="nav.user.viewProfile"
                  defaultMessage="View Profile"
                />
              </LinkButton>
            </div>
          </div>
          <UserMenu signout={signout} />
        </div>
      </Popper>
    </div>
  )
}

HeaderMenu.defaultProps = {}

export { HeaderMenu }
export default HeaderMenu
