export type EnvType = 'production' | 'staging' | 'preview' | 'development'

export const ENV: EnvType = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
  ? /staging/.test(process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL) ? 'staging' : 'preview'
  : process.env.NEXT_PUBLIC_VERCEL_ENV as EnvType

export const isProd = ENV === 'production'

export const API_ORIGIN = (() => {
  switch (ENV) {
    case 'production':
      return 'https://api.soundonlive.com'
    case 'staging':
    case 'preview':
      return 'https://api-git-staging-soundonlive1.vercel.app'
    default:
      return 'http://localhost:3010'
  }
})()

export const API_HOST = `${API_ORIGIN}/api`
export const AUTH_HOST = `${API_ORIGIN}/auth`
export const API_STATIC = `${API_ORIGIN}/static`

export const CDN_IMAGE_URL = isProd
  ? 'https://imazing.soundonlive.com'
  : 'https://imazing.soundonlive.com/dev'

export const CONTENT_URL = isProd
  ? 'https://d2yjbv29gqr1p4.cloudfront.net'
  : 'https://s3-ap-northeast-1.amazonaws.com/soundonlive-dev'

export const EMBED_URL = 'https://embed.soundonlive.com'

export const COOKIE_DOMAIN = (() => {
  switch (ENV) {
    case 'production':
      return '.soundonlive.com'
    // case 'staging':
    //   return 'soundonlive-git-staging-soundonlive1.vercel.app'
    // default:
    //   return 'localhost'
  }
})()
