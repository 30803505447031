// @flow
import { getToken } from '@utils/token'
import Cookies from 'universal-cookie'
import { API_HOST } from '../config'
import { logger, errorer } from './logger'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchWithErrorHandling = async (url, options: any = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const headers: any = {
    Accept: 'application/json',
  }

  if (options.method) {
    headers['Content-Type'] = 'application/json'
  }

  const token = options.token || getToken()
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const message = `Trying to ${options.method || 'GET'} from ${url}`
  logger(message)

  const cookies = new Cookies()
  const locale = cookies.get('NEXT_LOCALE') || 'en'

  try {
    return await (
      await fetch(url, {
        ...options,
        headers: {
          ...headers,
          ...options.headers,
          'Accept-Language': locale,
        },
      })
        .then((response) => {
          if (response.status >= 200 && response.status < 400) {
            return response
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const error: any = new Error(response.statusText)
          error.response = response
          return Promise.reject(response)
        })
        .catch((response) => {
          // eslint-disable-next-line no-console
          errorer('Error with ', response)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const error: any = new Error(response.statusText)
          error.response = response
          return Promise.reject(response)
        })
    ).json()
  }
  catch (err) {
    return { error: true }
  }
}

// const checkStatus = (response) => {
//   if (response.status >= 200 && response.status < 400) {
//     return response
//   }
//   const error = new Error(response.statusText)
//   error.response = response
//   return Promise.reject(response)
// }
//
// const getResponse = res => res.json()

export const get = (url, options = null) =>
  fetchWithErrorHandling(`${API_HOST}${url}`, {
    method: 'GET',
    ...options,
  })
export const getAbs = (url, options = null) =>
  fetchWithErrorHandling(`${url}`, {
    method: 'GET',
    ...options,
  })

export const post = (url, data = null, options = null) => {
  const body = JSON.stringify(data || {})
  return fetchWithErrorHandling(`${API_HOST}${url}`, {
    method: 'POST',
    body,
    ...options,
  })
}

export const put = (url, data, options = null) => {
  const body = JSON.stringify(data || {})
  return fetchWithErrorHandling(`${API_HOST}${url}`, {
    method: 'PUT',
    body,
    ...options,
  })
}

export const destroy = (url, options = null) =>
  fetchWithErrorHandling(`${API_HOST}${url}`, { method: 'DELETE', ...options })
