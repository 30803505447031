import React from 'react'
import Error from 'next/error'
import ErrorPage from '@pages/error'
import bugsnagClient from '@utils/bugsnag'

/*
interface Props {
  statusCode?: number | string
}
*/

const ErrorContainer = (props) => <ErrorPage {...props} />

/*
ErrorContainer.defaultProps = {
  statusCode: '503',
}
*/

ErrorContainer.getInitialProps = async (ctx) => {
  if (ctx.err) bugsnagClient.notify(ctx.err)
  return Error.getInitialProps(ctx)
}

export default ErrorContainer
