// @flow
import React from 'react'
import Link from 'next/link'
import { FormattedMessage } from 'react-intl'
import BreadcrumbItem from './item'
import styles from './breadcrumb.module.scss'

interface Props {
  items?: {
    to?: string
    as?: string
    label: string
  }[]
}

const Breadcrumb = ({ items }: Props) => (
  <nav aria-label="Breadcrumbs" className={styles.nav}>
    <div className="container">
      {/* eslint-disable-next-line react/no-unknown-property */}
      <ol className="breadcrumb container" vocab="http://schema.org/" typeof="BreadcrumbList">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <li property="itemListElement" typeof="ListItem">
          <Link
            href="/"
            className="nohover alt"
            property="item"
            typeof="WebPage"
          >
            <span itemProp="name">
              <span className="solicon-logo" />
              <span className="sr-only">
                <FormattedMessage id="nav.home" defaultMessage="Home" />
              </span>
            </span>
          </Link>
          <meta itemProp="position" content="0" />
        </li>
        {items.map((item, i) => (
          <BreadcrumbItem
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            {...item}
            position={i + 1}
          />
        ))}
      </ol>
    </div>
  </nav>
)

Breadcrumb.defaultProps = {
  items: [],
}

export default Breadcrumb
