import { CDN_IMAGE_URL } from '../config'

export const URL_REGEX = /d2r2wpjz1x7tg5|imazing|soundonlive-dev/

export const getFilename = (path) =>
  path.replace(/^.*[\\/]/, '').replace(/\?(.*)$/, '')

export const getCdnImage = (
  path: string,
  width: string | number,
  height: string | number,
) => {
  if (URL_REGEX.test(path)) {
    return path
  }
  const filename = getFilename(path)
  return `${CDN_IMAGE_URL}/fit/${width}/${height}/${filename}`
}

export const getCdnImageBlur = (
  path: string,
  width: string | number,
  height: string | number,
  blur: string | number,
) => {
  if (URL_REGEX.test(path)) {
    return path
  }
  const filename = getFilename(path)
  return `${CDN_IMAGE_URL}/fit/${width}/${height}/blur/${blur}/${filename}`
}
