// @flow
import React from 'react'
import Link from 'next/link'

interface Props {
  to?: string
  as?: string
  label: string
  position: number
}

const BreadcrumbItem = ({ to, as, label, position }: Props) => {
  const linkOptions = { to, as: '' }
  if (as) {
    linkOptions.as = as
  }

  return (
    // eslint-disable-next-line react/no-array-index-key, react/no-unknown-property
    <li property="itemListElement" typeof="ListItem">
      {to ? (
        <Link
          href={to}
          as={as}
          property="item"
          typeof="WebPage"
          className="alt"
        >
          <span itemProp="name">{label}</span>
        </Link>
      ) : (
        <span itemProp="name">{label}</span>
      )}
      <meta itemProp="position" content={position.toString()} />
    </li>
  )
}

BreadcrumbItem.defaultProps = {
  to: '',
  as: '',
}

export default BreadcrumbItem
