import MessagesCtx from '@data/contexts/messages'
import { AnimatePresence } from 'framer-motion'
import MessageItem from './item'
import styles from './messages.module.scss'

const Messages = () => {
  const { messages, deleteMessage } = MessagesCtx.useContainer()

  return (
    <div className={styles.container}>
      <AnimatePresence>
        {messages.map((item) => (
          <MessageItem
            key={`message-${item.id}`}
            message={item}
            deleteMessage={() => deleteMessage(item.id)}
          />
        ))}
      </AnimatePresence>
    </div>
  )
}

export default Messages
