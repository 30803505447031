import { useState } from 'react'
import { createContainer } from 'unstated-next'

interface Props {
  isLoading: boolean
  isPlaying: boolean
  nowPlaying: {
    index: number
    track: {
      id?: string
      slug?: string
      coverart?: string
      title?: string
      user?: string
      duration?: number
      waveformUrl?: string
      mp3Url?: string
      hlsUrl?: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      limit?: any
    }
  }
  tracks: {
    name: string
    path: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    list: any[]
  }
  seekTime: number
  isSeeking: boolean
  currentTime: number
  repeat: boolean
  repeat1: boolean
}

const initialState = {
  isLoading: false,
  isPlaying: false,
  nowPlaying: {
    index: 0,
    track: {},
  },
  tracks: {
    name: '',
    path: '',
    list: [],
  },
  seekTime: 0,
  isSeeking: false,
  currentTime: 0,
  repeat: false,
  repeat1: false,
}

const usePlayer = (props: Props = initialState) => {
  const [ isLoading, setLoading ] = useState(props.isLoading)
  const [ isPlaying, setPlaying ] = useState(props.isPlaying)
  const [ nowPlaying, setNowPlaying ] = useState(props.nowPlaying)
  const [ tracks, setTracks ] = useState(props.tracks)
  const [ seekTime, setSeekTime ] = useState(props.seekTime)
  const [ currentTime, setCurrentTime ] = useState(props.currentTime)
  const [ repeat, setStateRepeat ] = useState(props.repeat)
  const [ repeat1, setStateRepeat1 ] = useState(props.repeat1)

  const setRepeat = (payload: boolean) => {
    setStateRepeat(payload)
    setStateRepeat1(false)
  }

  const setRepeat1 = (payload: boolean) => {
    setStateRepeat1(payload)
    setStateRepeat(false)
  }

  const loading = () => {
    setLoading(true)
  }

  const loaded = () => {
    setLoading(false)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const doPlay = (payload: any) => {
    // if sound is now playing
    if (!payload || nowPlaying.track.id === payload.id) {
      setPlaying(true)
      return
    }
    // if sound already in tracks
    if (tracks.list.filter((s) => s.id === payload.id).length > 0) {
      // const track = state.tracks.list.find(s => s.id === payload.id)
      const index = tracks.list.findIndex((s) => s.id === payload.id)
      const track = tracks.list[index]
      setPlaying(true)
      setNowPlaying({ index, track })
      setSeekTime(0)
      return
    }
    setPlaying(true)
    setTracks({
      name: '',
      path: '',
      list: [ ...tracks.list, payload ],
    })
    setNowPlaying({
      index: tracks.list.length,
      track: payload,
    })
    setSeekTime(0)
  }

  const pause = () => {
    setPlaying(false)
  }

  const forward = () => {
    const { length } = tracks.list
    if (length < 2) {
      return
    }
    let index = nowPlaying.index + 1
    if (index >= length && !repeat && !repeat1) {
      return
    }
    if (index >= length) {
      index = 0
    }
    const track = tracks.list[index]
    setPlaying(true)
    setNowPlaying({ index, track })
    setSeekTime(0)
  }

  const backward = () => {
    const { length } = tracks.list
    if (length < 2) {
      return
    }
    const index = nowPlaying.index - 1
    if (index < 0) {
      return
    }
    const track = tracks.list[index]
    setPlaying(true)
    setNowPlaying({ index, track })
    setSeekTime(0)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setPlaylist = (payload: any) => {
    setTracks(payload)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const play = async (payload?: any) => {
    if (!payload || nowPlaying.track.id === payload.id) {
      return doPlay(payload)
    }

    return doPlay(payload)
  }

  return {
    isLoading,
    isPlaying,
    nowPlaying,
    tracks,
    seekTime,
    currentTime,
    repeat,
    repeat1,
    loading,
    loaded,
    setLoading,
    doPlay,
    pause,
    forward,
    backward,
    setCurrentTime,
    setSeekTime,
    setRepeat,
    setRepeat1,
    setPlaylist,
    play,
  }
}

const Player = createContainer(usePlayer)

export default Player
