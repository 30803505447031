import mitt from 'mitt'

const noop = (f) => f

class Uploader {
  isUploading = false

  request = null

  constructor(options) {
    const request = new XMLHttpRequest()
    request.upload.addEventListener('progress', this.updateProgress)
    request.upload.addEventListener('load', this.transferComplete)
    request.upload.addEventListener('error', this.transferFailed)
    this.request = request
    this.options = options
  }

  options = {}

  events = mitt()

  onProgress = noop

  onComplete = noop

  onFailed = noop

  open(method = 'PUT', url) {
    this.isUploading = true
    this.request.open(method, url, true)
    this.request.setRequestHeader('Accept', 'application/json, text/plain, */*')
    return this
  }

  send(body) {
    this.request.send(body)
    return this
  }

  progress(onProgress) {
    this.onProgress = onProgress
    return this
  }

  then(onComplete) {
    this.onComplete = onComplete
    return this
  }

  catch(onFailed) {
    this.onFailed = onFailed
    return this
  }

  abort() {
    this.isUploading = false
    this.request.abort()
  }

  updateProgress = (event) => {
    // do not call onProgress after abort
    if (!this.isUploading) {
      return
    }
    this.onProgress(event)
    this.events.emit('progress', { ...event, ...this.options })
  }

  transferComplete = (event) => {
    this.onComplete(event)
    this.events.emit('complete', { ...event, ...this.options })
  }

  transferFailed = (event) => {
    this.onFailed(event)
    this.events.emit('error', { ...event, ...this.options })
  }
}

export default Uploader
