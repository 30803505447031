import Image from 'next/image'
// import { useRouter } from 'next/router'
import ReactCookieConsent from 'react-cookie-consent'


export default function CookieConsent() {
  // const router = useRouter()
  
  return (
    <ReactCookieConsent
      // onAccept={() => router.reload()}
      containerClasses="cookie-container"
      contentClasses="cookie-content"
      buttonWrapperClasses="cookie-button-wrapper"
      buttonClasses="cookie-button"
      buttonText="Okay, thanks"
      sameSite="lax"
    >
      <div className="d-flex align-items-center">
        <Image
          src="/static/images/cookie.png"
          alt="cookie"
          width={96}
          height={96}
        />
        <div className="tx-16 tx-oswald tx-white tx-bold">
          This site uses cookies:&nbsp;
          <a
            href="https://www.cookiebot.com/en/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Find out more.
          </a>
          &nbsp;If you want to bake cookie,&nbsp;
          <a
            href="https://www.allrecipes.com/recipe/10813/best-chocolate-chip-cookies/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here
          </a>
        </div>
      </div>
    </ReactCookieConsent>
  )
}
