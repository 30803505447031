// @flow
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './navbar.module.scss'
import Button from '@components/common/button'
import SigninModal from '@components/modals/signin'

const Signin = () => {
  const [ isOpen, setOpen ] = useState(false)
  const [ isSignin, setSignin ] = useState(false)
  const toggleModal = (signin) => () => {
    setSignin(signin)
    setOpen(!isOpen)
  }

  return (
    <>
      <li className="d-none d-md-block">
        <button
          type="button"
          className="btn-chromeless tx-g900 px-2"
          onClick={toggleModal(true)}
        >
          <FormattedMessage id="app.signin" defaultMessage="Sign in" />
        </button>
      </li>
      <li>
        <Button
          className={`${styles.btn} primary`}
          onClick={toggleModal(false)}
        >
          <FormattedMessage id="app.getstart" defaultMessage="Get Started" />
        </Button>
      </li>

      <SigninModal
        open={isOpen}
        isSignin={isSignin}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

Signin.defaultProps = {}

export default Signin
