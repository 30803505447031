// @flow
import { get, post } from '../../utils/fetch'

export const postTranscode = async (filename: string) => {
  const transcode = await post('/transcode/', { filename })
  return transcode
}

export const getTranscodeStatus = async (id: string) => {
  const status = await get(`/transcode/status/${id}`)
  return status
}
