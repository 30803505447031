// @flow
import React from 'react'
import classnames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { FormattedMessage } from 'react-intl'

// import logoSvg from '@static/logo-inline.svg'
import useAuth from '@/hooks/auth'
import Menu from './header-menu'
import Signin from './signin'
import styles from './navbar.module.scss'
import LinkButton from '@components/common/button/link'

interface Props {
  plain?: boolean
  inverse?: boolean
}

const Navbar = ({ plain, inverse }: Props) => {
  // const { loggedin } = AuthCtx.useContainer()
  const { loggedin } = useAuth()

  return (
    <header
      className={classnames(styles.header, { [styles.inverse]: inverse })}
    >
      <div className="container">
        <div className="d-flex align-items-stretch justify-content-between">
          <div className={`${styles.navCenter} mr-5`}>
            <Link href="/" className={`float-left ${styles.logo}`}>
              <Image
                src="/static/logo-inline.svg"
                alt="SOUND ON LIVE"
                width={135}
                height={27}
              />
            </Link>
          </div>
          {
            !plain && (
              <div className={`${styles.navSection} justify-content-end`}>
                <ul className={`${styles.nav} m-0`}>
                  <li className={styles.search}>
                    <Link
                      href="/search"
                      className="tx-g900 nohover px-2"
                      aria-label="search"
                    >
                      <i className="solicon-search" />
                    </Link>
                  </li>
                  {
                    loggedin ? (
                      <>
                        {/* <li className={styles.search}>
                          <button type="button" className="btn-chromeless px-2 tx-g900">
                            <i className="solicon-bell" />
                          </button>
                        </li> */}
                        <li className="d-none d-md-block">
                          <LinkButton
                            href="/sound-new"
                            className={`${styles.btn} primary`}
                          >
                            <FormattedMessage
                              id="app.upload"
                              defaultMessage="Upload new sound"
                            />
                          </LinkButton>
                        </li>
                        <li>
                          <Menu />
                        </li>
                      </>
                    ) : (
                      <Signin />
                    )
                  }
                </ul>
              </div>
            )
          }
        </div>
      </div>
    </header>
  )
}

Navbar.defaultProps = {
  plain: false,
  inverse: false,
}

export { Navbar as NavbarComponent }

export default Navbar
