import { useCallback } from 'react'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FormattedMessage } from 'react-intl'
import useAuth from '@/hooks/auth'
import styles from './footer.module.scss'
import LocaleDropdown from '@components/app/locale-dropdown'
import { updateMe } from '@data/api/auth'

const version = '2.1.1'

const Footer = () => {
  const { loggedin } = useAuth()
  const router = useRouter()

  const switchLocale = useCallback(async (locale) => {
    if (loggedin) {
      const result = await updateMe({ locale })
      if (result.error) {
        // eslint-disable-next-line no-console
        console.error(result.error)
      }
    }

    const { pathname, asPath, query } = router
    router.push({ pathname, query }, asPath, { locale: locale })
  }, [ loggedin, router ])

  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 mb-3">
            <Image
              src="/static/images/logo-inline-white.svg"
              alt="SOUND ON LIVE"
              width={170}
              height={33.83}
            />
          </div>
          <div className={`col-12 col-md-6 mb-3 ${styles.mdRight}`}>
            <div className="mb-3">
              <a
                className={styles.link}
                href="https://onlive.studio/"
                rel="noopener noreferrer"
                target="_blank"
              >
                音楽制作をプロに依頼するなら<br />
                <strong className="tx-white">「ONLIVE Studio」</strong>
              </a>
            </div>
            <div>
              <a
                className={styles.link}
                href="https://blog.onlive.studio/"
                rel="noopener noreferrer"
                target="_blank"
              >
                音楽制作に携わる人へ贈る情報メディア<br />
                <strong className="tx-white">「ONLIVE Studio blog」</strong>
              </a>
            </div>
          </div>
        </div>
        <div className={styles.hr} />
        <div className="row">
          <div className="col-12 col-md-6">
            <nav className="mb-3">
              <ul className={styles.ul}>
                <li className={styles.li}>
                  <Link href="/" className={styles.link}>
                    <FormattedMessage id="nav.home" defaultMessage="Home" />
                  </Link>
                </li>
                <li className={styles.li}>
                  <Link href="/company" className={styles.link}>
                    <FormattedMessage
                      id="nav.company"
                      defaultMessage="Company"
                    />
                  </Link>
                </li>
                <li className={styles.li}>
                  <a
                    className={styles.link}
                    href="https://blog.onlive.studio/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FormattedMessage id="nav.blog" defaultMessage="Blog" />
                  </a>
                </li>
                <li className={styles.li}>
                  <Link
                    href="/terms"
                    className={styles.link}
                  >
                    <FormattedMessage id="nav.terms" defaultMessage="Terms" />
                  </Link>
                </li>
                <li className={styles.li}>
                  <Link
                    href="/privacy"
                    className={styles.link}
                  >
                    <FormattedMessage
                      id="nav.privacy"
                      defaultMessage="Privacy"
                    />
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className={`col-12 col-md-6 ${styles.mdRight}`}>
            <div className="d-inline-block tx-14 mb-3">
              <LocaleDropdown
                className={styles.locale}
                onChange={(locale) => switchLocale(locale)}
              />
            </div>
          </div>
        </div>
        <div className="row tx-12">
          <div className="col-12 col-md-6 copyright mb-3">
            (C) SOUND ON LIVE, Inc. with whole a lot of ♥
          </div>
          <div className={`col-12 col-md-6 md-right mb-3 ${styles.mdRight}`}>
            <span className="d-block d-md-inline mr-2">許諾番号</span>
            <span className="d-inline-block mr-2">JASRAC:9016480001Y30005</span>
            <span className="d-inline-block">NexTone: ID000000300</span>
            <div className="tx-12 py-1">v{version}</div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
