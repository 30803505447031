// @flow
import * as React from 'react'
import ReactModal from 'react-modal'

import styles from './modal.module.scss'

interface Props {
  isOpen?: boolean
  className?: string
  contentLabel?: string
  children: React.ReactNode
  onRequestClose: () => void
}

const Modal = ({ className, children, onRequestClose, ...props }: Props) => (
  <ReactModal
    portalClassName={styles.portal}
    overlayClassName={styles.overlay}
    className={`${styles.content} ${className}`}
    bodyOpenClassName={styles.bodyOpen}
    htmlOpenClassName={styles.htmlOpen}
    ariaHideApp={false}
    closeTimeoutMS={200}
    {...props}
  >
    <button
      type="button"
      className={`btn-chromeless ${styles.closeBtn}`}
      aria-label="Close"
      onClick={onRequestClose}
    />
    {children}
  </ReactModal>
)

Modal.defaultProps = {
  isOpen: false,
  className: '',
  contentLabel: '',
}

export default Modal
