import React from 'react'
// import Hls from 'hls.js'

// const FADE_TIME = 2
// eslint-disable-next-line max-len
const silence =
  'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA'

interface Props {
  mp3Url: string
  hlsUrl?: string
  isPlaying: boolean
  seekTime?: number
  limit?: {
    on: boolean
    min: number
    max: number
  }
  onEnded: () => void
  onLoadUpdate: (loading: boolean) => void
  onTimeUpdate: (time: number) => void
}

export class AudioPlayer extends React.Component<Props> {
  // eslint-disable-next-line react/static-property-placement
  public static defaultProps: Partial<Props> = {
    hlsUrl: '',
    seekTime: 0,
    limit: {
      on: false,
      min: 0,
      max: 0,
    },
  }

  audio = null

  audioCtx = null

  gainNode = null

  isLoading = true

  playPromise = null

  componentDidMount() {
    // const { onEnded } = this.props
    // this.audio = new Audio()
    // this.audio.crossOrigin = 'anonymous'
    // this.audio.src = silence

    // this.audio.addEventListener('loadstart', this.onLoadStart)
    // this.audio.addEventListener('timeupdate', this.onTimeUpdate)
    // this.audio.addEventListener('canplay', this.onCanPlay)
    // this.audio.addEventListener('ended', onEnded)

    /* eslint-disable */
    // this.audio.addEventListener('loadstart', () => { console.log('loadstart') })
    // this.audio.addEventListener('progress', (e) => { console.log('progress', e) })
    // this.audio.addEventListener('waiting', () => { console.log('waiting') })
    this.audio.addEventListener('suspend', () => {
      console.log('suspend')
      const { isPlaying } = this.props
      if (isPlaying) {
        // this.audioCtx.resume()
        this.play()
      }
    })
    // this.audio.addEventListener('abort', () => { console.log('abort') })
    // this.audio.addEventListener('error', (error) => {
    //   console.error(error)
    // })
    // this.audio.addEventListener('emptied', () => { console.log('emptied') })
    // this.audio.addEventListener('ended', () => { console.log('ended') })
    // this.audio.addEventListener('loadedmetadata', (e) => { console.log('loadedmetadata', e) })
    // this.audio.addEventListener('loadeddate', () => { console.log('loadeddata') })
    // this.audio.addEventListener('canplay', () => { console.log('canplay') })
    // this.audio.addEventListener('canplaythrough', () => { console.log('canplaythrough') })
    // this.audio.addEventListener('play', () => { console.log('play') })
    // this.audio.addEventListener('seeking', () => { console.log('seeking') })
    // this.audio.addEventListener('seeked', () => { console.log('seeked') })
    // this.audio.addEventListener('durationchange', () => { console.log('durationchange') })
    // this.audio.addEventListener('ratechange', () => { console.log('ratechange') })
    // this.audio.addEventListener('volumechange', () => { console.log('volumechange') })
    // this.audio.addEventListener('stalled', () => { console.log('stalled') })
    /* eslint-enable */

    try {
      // const AudioContext = window.AudioContext || window.webkitAudioContext
      // const audioCtx = new AudioContext()
      // const source = audioCtx.createMediaElementSource(this.audio)
      // this.gainNode = audioCtx.createGain()
      // source.connect(this.gainNode)
      // this.gainNode.connect(audioCtx.destination)
      //
      // this.audioCtx = audioCtx
    }
    catch (e) {
      // TODO: Show Error Message
      // eslint-disable-next-line no-console
      console.error('Web Audio Error')
      // eslint-disable-next-line no-console
      console.error(e)
    }

    // to prevent safari audio handling
    this.audio.src = silence
    window.addEventListener('touchstart', this.playSilence, false)
    window.addEventListener('click', this.playSilence, false)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shouldComponentUpdate(props: any) {
    const { isPlaying, seekTime, mp3Url, hlsUrl } = this.props

    return (
      props.isPlaying !== isPlaying ||
      props.seekTime !== seekTime ||
      props.mp3Url !== mp3Url ||
      props.hlsUrl !== hlsUrl
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidUpdate(prevProps: any) {
    const { isPlaying, seekTime, mp3Url } = this.props
    if (prevProps.isPlaying !== isPlaying && !this.isLoading) {
      if (isPlaying) {
        this.play()
      }
      else if (this.playPromise !== undefined) {
        this.playPromise
          .then(() => {
            this.audio.pause()
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            // eslint-disable-next-line no-console
            console.log(error)
          })
      }
    }

    if (prevProps.seekTime !== seekTime) {
      this.audio.currentTime = seekTime
    }

    if (prevProps.mp3Url !== mp3Url) {
      this.onSrcChange()
    }
  }

  componentWillUnmount() {
    window.addEventListener('touchstart', this.playSilence, false)
    window.addEventListener('click', this.playSilence, false)
  }

  onSrcChange = () => {
    const {
      mp3Url,
      // hlsUrl,
      onTimeUpdate,
      limit,
    } = this.props
    if (!mp3Url || typeof mp3Url !== 'string') {
      return
    }
    onTimeUpdate(0)

    this.audio.src = mp3Url
    // this.audio.src = hlsUrl
    this.audio.volume = 1
    if (limit.on) {
      this.audio.currentTime = limit.min || 0
      onTimeUpdate(limit.min || 0)
      // // Schedule fade in
      // this.gainNode.gain.linearRampToValueAtTime(0, this.audioCtx.currentTime)
      // this.gainNode.gain.linearRampToValueAtTime(1, this.audioCtx.currentTime + FADE_TIME)
      // // Schedule fade out
      // this.gainNode.gain.linearRampToValueAtTime(
      //   1,
      //   this.audioCtx.currentTime + (limit.max - limit.min) - FADE_TIME,
      // )
      // this.gainNode.gain.linearRampToValueAtTime(
      //   0,
      //   this.audioCtx.currentTime + (limit.max - limit.min),
      // )
    }
    else {
      // this.gainNode.gain.cancelScheduledValues(this.audioCtx.currentTime)
      // this.gainNode.gain.value = 1
    }
    this.audio.load()
  }

  onLoadStart = () => {
    const { onLoadUpdate } = this.props
    this.isLoading = true
    onLoadUpdate(true)
  }

  // partial load finished
  onCanPlayThrough = () => {
    const { isPlaying, onLoadUpdate, limit } = this.props
    this.isLoading = false
    onLoadUpdate(false)

    if (!isPlaying) {
      return
    }
    if (limit.on && this.audio.currentTime < limit.min && !this.audio.paused) {
      this.audio.currentTime = limit.min || 0
    }
    this.play()
    // this.audioCtx.resume()
  }

  onTimeUpdate = () => {
    const { onTimeUpdate, onEnded, limit } = this.props
    const { currentTime } = this.audio
    if (limit.on && currentTime < limit.min) {
      this.audio.currentTime = limit.min || 0
      return
    }
    if (!limit.on || currentTime < limit.max) {
      onTimeUpdate(this.audio.currentTime)
      return
    }
    this.audio.pause()
    this.audio.currentTime = limit.min || 0
    onEnded()
  }

  play = () => {
    if (!this.audio) {
      return
    }
    this.playPromise = this.audio.play()
  }

  playSilence = () => {
    // this.play()
    window.removeEventListener('touchstart', this.playSilence)
    window.removeEventListener('click', this.playSilence)
  }

  render() {
    // return null
    const { onEnded } = this.props
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <audio
        ref={(ref) => {
          this.audio = ref
        }}
        onLoadStart={this.onLoadStart}
        onCanPlayThrough={this.onCanPlayThrough}
        onTimeUpdate={this.onTimeUpdate}
        onEnded={onEnded}
        crossOrigin="anonymous"
      />
    )
  }
}

export default AudioPlayer
