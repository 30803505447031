// @flow
import Router from 'next/router'
import NProgress from 'nprogress'

let configured = false

const nProgress = () => {
  if (configured) {
    return
  }
  configured = true
  NProgress.configure({ showSpinner: false })

  // use below when next.js has been updated
  Router.events.on('routeChangeStart', () => NProgress.start())
  Router.events.on('routeChangeComplete', () => NProgress.done())
  Router.events.on('routeChangeError', () => NProgress.done())
}

export default nProgress
