import { useState } from 'react'
import { nanoid } from 'nanoid'
import { createContainer } from 'unstated-next'

export interface Message {
  id: string
  type: 'info' | 'warning' | 'danger'
  message: string
}

const initialState: Message[] = []

const useMessage = (props: Message[] = initialState) => {
  const [ messages, setMessages ] = useState(props)
  const deleteMessage = (id: string) => {
    setMessages((prevState) => prevState.filter((m) => m.id !== id))
  }

  const addMessage = (message: string | Message) => {
    const id = nanoid(12)
    const defaults: Message = { id, type: 'info', message: '' }
    const data: Message =
      typeof message === 'string'
        ? { ...defaults, message }
        : { ...defaults, ...message }
    setMessages((prevState) => [ ...prevState, data ])
  }

  return {
    messages,
    deleteMessage,
    addMessage,
  }
}

const Messages = createContainer(useMessage)

export default Messages
