// @flow
import * as React from 'react'
import classnames from 'classnames'
import styles from './button.module.scss'

interface Props {
  children: React.ReactNode
  id?: string
  type?: 'button' | 'submit' | 'reset'
  className?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => void
  disabled?: boolean
}

const Button = ({
  children,
  id,
  type,
  onClick,
  className,
  disabled,
}: Props) => {
  const classes = className.split(' ').map((name) => styles[name] || name)
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      id={id}
      className={classnames(styles.btn, classes)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

Button.defaultProps = {
  id: '',
  type: 'button',
  className: '',
  onClick: (f) => f,
  disabled: false,
}

export default Button
