import React from 'react'
// import Link from 'next/link'
import styles from './error.module.scss'

interface Props {
  statusCode?: number | string
}

const ErrorPage = ({ statusCode }: Props) => {
  const image =
    `${statusCode}` === '404'
      ? '/static/images/404.jpg'
      : '/static/images/500.jpg'

  return (
    <div className={`error-container ${styles.container}`}>
      <style jsx>
        {`
          .error-container {
            background-image: url(${image});
          }
        `}
      </style>
      <div className="d-md-flex align-items-center justify-content-center p-5">
        <div className="flex0 tx-center m-3">
          <span className={`${styles.code} d-inline-block tx-oswald`}>
            {statusCode}
          </span>
        </div>
        {`${statusCode}` === '404' ? (
          <div className="flex1">
            <h1 className={`${styles.h1} tx-oswald`}>
              Sorry, but no pages exists here.
            </h1>
            <p className="tx-24">Find something interesting to listen.</p>
          </div>
        ) : (
          <div className="flex1">
            <h1 className={`${styles.h1} tx-oswald`}>
              Sorry, something happened.
            </h1>
            <p className="tx-24">Please access again later.</p>
          </div>
        )}
      </div>
    </div>
  )
}

ErrorPage.defaultProps = {
  statusCode: 404,
}

export default ErrorPage
