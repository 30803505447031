import React from 'react'
import classnames from 'classnames'
import styles from './spinner.module.scss'

interface Props {
  className?: string
  size?: number | string
  inverse?: boolean
}

const Spinner = ({ size, className, inverse }: Props) => {
  const width = typeof size === 'number' ? `${size}px` : size
  return (
    <svg
      className={classnames(`${styles.svg} ${className}`, {
        [styles.color]: !inverse,
        [styles.white]: inverse,
      })}
      viewBox="0 0 66 66"
    >
      <style jsx>
        {`
          svg {
            width: ${width};
            height: ${width};
          }
        `}
      </style>
      <circle
        className={styles.circle}
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  )
}

Spinner.defaultProps = {
  className: '',
  size: 42,
  inverse: false,
}

export default Spinner
