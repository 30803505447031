// @flow
import { get } from '../../utils/fetch'
import Uploader from '../../utils/upload'

const defaultType = 'application/octet-stream'

export const getUploadImageUrl = async (
  name: string,
  type: string = defaultType,
) => {
  const response = await get(`/upload/images/policy/?name=${name}&type=${type}`)
  return response
}

export const getUploadSoundUrl = async (
  name: string,
  type: string = defaultType,
) => {
  const response = await get(`/upload/sounds/policy/?name=${name}&type=${type}`)
  return response
}

export const uploadFile = (url: string, file, params, options = {}) => {
  const formData = new FormData()
  Object.keys(params).forEach((key) => {
    if (key === 'filename') {
      return
    }
    formData.append(key, params[key])
  })
  formData.append('file', file)

  const uploader = new Uploader(options)
  uploader.open('POST', url)
  uploader.send(formData)

  return uploader
}

// this doesnt work for some reason
// export const uploadSoundFile = async file => new Promise((res) => {
//   getUploadSoundUrl(file.name, file.type)
//     .then(({ url, params }) => {
//       const uploader = uploadFile(url, file, params)
//       console.log(uploader)
//       res(uploader)
//     })
// })
