import { memo, useEffect, useRef, useState } from 'react'
import { Message } from '@data/contexts/messages'
import { motion } from 'framer-motion'
import styles from './messages.module.scss'

type Props = {
  message: Message
  deleteMessage: () => void
}

const MessageItem = ({ message, deleteMessage }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [ height, setHeight ] = useState(0)

  useEffect(() => {
    setHeight(ref.current?.offsetHeight ?? 0)
  }, [])

  return (
    <motion.div
      className={styles.message}
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ tension: 25, friction: 20, precision: 0.1 }}
      onAnimationComplete={() => {
        setTimeout(() => {
          deleteMessage()
        }, 5000)
      }}
    >
      <div ref={ref} className={styles.content}>
        <motion.div
          className={styles.life}
          initial={{ right: '100%' }}
          animate={{ right: '0%' }}
        />
        <p className={styles.p}>{message.message}</p>
        <button
          type="button"
          className={styles.btn}
          onClick={deleteMessage}
        >
          <i className="solicon-close" />
        </button>
      </div>
    </motion.div>
  )
}

export default memo(MessageItem)
