// @flow
import React, { useState, useRef, useEffect } from 'react'
import { timenize } from '@utils/string'
import classnames from 'classnames'
import Image from 'next/legacy/image'
import { FormattedMessage } from 'react-intl'
import styles from './player.module.scss'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tracks: any
  disabled?: boolean
  current: number
  onClick: (index: number) => void
}

const Playlist = ({ tracks, disabled, current, onClick }: Props) => {
  const [ isOpen, setOpen ] = useState(false)
  const containerRef = useRef<HTMLDivElement>()

  // Event Listener
  const onWindowClick = (e) => {
    try {
      if (containerRef.current.contains(e.target)) {
        return
      }
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
    setOpen(false)
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', onWindowClick)
      document.addEventListener('touchend', onWindowClick)
    }
    return () => {
      document.removeEventListener('click', onWindowClick)
      document.removeEventListener('touchend', onWindowClick)
    }
  }, [ isOpen ])

  // const onListClick = (index) => {
  //   // do something
  //   removeEvent()
  // }

  return (
    <div className="ps-relative" ref={containerRef}>
      <button
        type="button"
        className={`${styles.btn} ${styles.playlist}`}
        onClick={() => setOpen(!isOpen)}
        disabled={disabled}
      >
        <Image
          src="/static/images/icons/playlist.svg"
          alt="Playlist"
          width={17}
          height={11}
        />
      </button>
      <div
        className={classnames(styles.list, { [styles.open]: isOpen })}
      >
        {tracks.list.length > 0 && (
          <div className={styles.listTitle}>
            <span className="tx-14 tx-gish">
              Playlist
              {/* Playing from&nbsp;
                <strong>{tracks.name}</strong> */}
            </span>
          </div>
        )}
        <div className={styles.listBody}>
          {tracks.list.map((track, index) => (
            <button
              key={track.id}
              type="button"
              // eslint-disable-next-line max-len
              className={classnames(
                styles.listItem,
                'w100p btn-chromeless d-flex align-items-center tx-left tx-l12 px-3 pb-0 pt-3',
                { [styles.active]: index === current },
              )}
              onClick={() => onClick(index)}
            >
              <span className="flex0 d-block pb-3">
                <img
                  className={styles.coverart}
                  src={track.coverart || '/static/images/cover.png'}
                  alt={track.title}
                />
              </span>
              <span className="d-flex flex1 border-b200 align-items-center pb-3 pl-2">
                <span className="d-block flex1 mw0">
                  <span className="d-block tx-14 tx-w500 tx-black tx-ellipsis">
                    {track.title}
                  </span>
                  <span className="d-block tx-12 tx-g700 tx-ellipsis">
                    {track.user}
                  </span>
                </span>
                <span className="d-block flex0 tx-g700 tx-12">
                  {timenize(track.duration)}
                </span>
              </span>
            </button>
          ))}
          {tracks.list.length === 0 && (
            <div className="d-flex align-items-center h100p tx-center tx-gish p-3">
              <FormattedMessage
                id="playlist.noitem"
                defaultMessage="You don't have any sound in your playlist."
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

Playlist.defaultProps = {
  disabled: false,
}

export default Playlist
