import { useCallback, useEffect } from 'react'
import Router from 'next/router'
import useSWR from 'swr'
import Cookies from 'universal-cookie'
import { getMe, updateMe } from '@/data/api/auth'
import { COOKIE_DOMAIN } from '@/config'

function fetcher() {
  return getMe()
}

export default function useAuth() {
  const { data: currentUser, isLoading, mutate } = useSWR('/api/auth/me', fetcher, { revalidateIfStale: false })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateUser = useCallback((user: any) => {
    mutate({ ...currentUser, ...user })
  }, [ currentUser, mutate ])

  const signout = useCallback(() => {
    const cookies = new Cookies()
    cookies.remove('token', { domain: COOKIE_DOMAIN, path: '/' })
    mutate(null)
    return {
      loggedin: false,
      currentUser: {},
    }
  }, [ mutate ])

  const signin = useCallback(async (token: string) => {
    const user = await getMe(token)
    const cookies = new Cookies()
    cookies.set('token', token, {
      domain: COOKIE_DOMAIN,
      path: '/',
      maxAge: 604800000,
    })
    mutate(user)
  }, [ mutate ])

  const updateUserData = useCallback(async (params: unknown) => {
    const updatedUser = await updateMe(params)
    if (updatedUser.error) {
      return Promise.reject(updatedUser.error)
    }
    mutate(updatedUser)
    return updatedUser
  }, [ mutate ])

  useEffect(() => {
    if (currentUser && !currentUser.registered_at) {
      if (Router.pathname === '/register') {
        return
      }
      const routes = {
        route: Router.route,
        asPath: Router.asPath,
        query: Router.query,
        pathname: Router.pathname,
      }
      window.sessionStorage.setItem('authRedirect', JSON.stringify(routes))
      Router.push('/register')
    }
  }, [ currentUser ])

  return {
    loggedin: !!currentUser,
    currentUser,
    isAuthLoading: isLoading,
    signedin: mutate,
    updateUser,
    signout,
    signin,
    updateUserData,
  }
}
