import { isProd } from '@/config'

export const logger = (...props) => {
  if (isProd) {
    return
  }
  // eslint-disable-next-line no-console
  console.log(...props)
}

export const infor = (...props) => {
  if (isProd) {
    return
  }
  // eslint-disable-next-line no-console
  console.info(...props)
}

export const errorer = (...props) => {
  if (isProd) {
    return
  }
  // eslint-disable-next-line no-console
  console.error(...props)
}
