// @flow
import React from 'react'
import Modal from '../modal'
import Auth from '@components/app/auth'

interface Props {
  open: boolean
  isSignin?: boolean
  onClose: () => void
}

const SigninModal = ({ open, isSignin, onClose }: Props) => (
  <Modal isOpen={open} contentLabel="Signup" onRequestClose={onClose}>
    <Auth isSignin={isSignin} />
  </Modal>
)
//
// type States = {
//   showModal: boolean,
// }
//
// class SigninModal extends React.Component<Props, States> {
//   constructor(props) {
//     super(props)
//     this.state = {
//       showModal: props.open,
//     }
//   }
//
//   static getDerivedStateFromProps(nextProps, prevState) {
//     if (nextProps.open !== prevState.showModal) {
//       return { showModal: nextProps.open }
//     }
//     return null
//   }
//
//   render() {
//     const { onClose } = this.props
//     const { showModal } = this.state
//     return (
//       <Modal
//         isOpen={showModal}
//         contentLabel="Signup"
//         onRequestClose={onClose}
//       >
//         <Auth signin />
//       </Modal>
//     )
//   }
// }

SigninModal.defaultProps = {
  isSignin: false,
}

export default SigninModal
