import React from 'react'
import classnames from 'classnames'
import CdnImage from '../cdn-image'
// import Image from 'next/image'
import styles from './avatar.module.scss'

interface Props {
  className?: string
  src?: string
  alt?: string
  width?: string | number
}

const Avatar = ({
  className = '',
  src = '/static/images/avatars/0.svg',
  alt = '',
  width = 400,
}: Props) => {
  const classNames = classnames('maxW100p circle shadow-avatar aspect-square', styles.avatar, className)

  const size = width === 'auto' || isNaN(+width) ? 400 : +width
  return (
    <CdnImage
      className={classNames}
      src={src}
      alt={`${alt} avatar`}
      width={size}
      height={size}
    />
  )
}

export default Avatar
