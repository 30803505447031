// File types
export const AUDIO_FILE_TYPES = '.flac,audio/*'
export const IMAGE_FILE_TYPES = 'image/*'

// Genre list
export const GENRE_LIST = [
  'alternative',
  'animation',
  'blues',
  'classics',
  'dance',
  'djmix',
  'electronic',
  'family',
  'folk',
  'hiphop',
  'jazz',
  'metal',
  'pop',
  'rb',
  'reggae',
  'rock',
  'soundtracks',
  'world',
]

// Sound publicity
export const PUBLICITY_PUBLIC = 'Released'
export const PUBLICITY_PRIVATE = 'Private'
export const PUBLICITY_DRAFT = 'Draft'
