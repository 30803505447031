import { useMemo } from 'react'
import Image, { ImageProps } from 'next/image'
import { getCdnImage, getCdnImageBlur } from '@/utils/cdn-image'

type Props = ImageProps & {
  defaultSrc?: string
  blur?: number
  width: number
  height: number
}

const CdnImage = ({
  defaultSrc = '/static/images/cover.png',
  src: initialSrc,
  width,
  height,
  alt,
  blur,
  ...props
}: Props) => {
  const src = useMemo(() => {
    if (
      !initialSrc
      || (/^http|^\/static|^data:/i.test(`${initialSrc}`)
      || typeof initialSrc !== 'string')
    ) {
      return initialSrc
    }

    if (blur) {
      return getCdnImageBlur(initialSrc, width, height, blur)
    }

    return getCdnImage(initialSrc, width, height)
  }, [ initialSrc, width, height, blur ])

  return (
    <Image
      src={src || defaultSrc}
      width={width}
      height={height}
      alt={alt}
      {...props}
    />
  )
}

export default CdnImage
