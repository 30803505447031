import classNames from 'classnames'
import { useRouter } from 'next/router'
import styles from './locale-dropdown.module.scss'

interface Props {
  className: string
  onChange: (locale: string) => void
}

const LocaleDropdown = ({ className = '', onChange }: Props) => {
  const router = useRouter()
  return (
    <div className={classNames(styles.select, className)}>
      <button
        className="btn-chromeless"
        type="button"
        disabled={router.locale === 'ja'}
        onClick={() => onChange('ja')}
      >
        日本語
      </button>
      <button
        className="btn-chromeless"
        type="button"
        disabled={router.locale === 'en'}
        onClick={() => onChange('en')}
      >
        English
      </button>
    </div>
  )
}

export default LocaleDropdown
