// @flow
import React from 'react'
import Link from 'next/link'
import { FormattedMessage } from 'react-intl'

import styles from './navbar.module.scss'

interface Props {
  signout: () => void
}

const component = ({ signout }: Props) => (
  <ul className={styles.menuList}>
    <li role="presentation" className={`px-3 ${styles.menuHeader}`}>
      <FormattedMessage id="app.artisttool" defaultMessage="Artist tools" />
    </li>
    <li>
      <Link href="/sound-new" className={styles.link}>
        <span className="icon solicon-music-alt mr-3" />
        <FormattedMessage id="app.newsound" defaultMessage="New sound" />
      </Link>
    </li>
    <li>
      <Link href="/my/sounds" className={styles.link}>
        <span className="icon solicon-layout-list-thumb mr-3" />
        <FormattedMessage id="app.mysound" defaultMessage="My sounds" />
      </Link>
    </li>
    <li>
      <Link href="/my/reports" className={styles.link}>
        <span className="icon solicon-bar-chart mr-3" />
        <FormattedMessage id="app.report" defaultMessage="Report" />
      </Link>
    </li>
    <li>
      <Link href="/my/purchases" className={styles.link}>
        <span className="icon solicon-layout-list-thumb-alt mr-3" />
        <FormattedMessage id="app.purchased" defaultMessage="Shopping List" />
      </Link>
    </li>
    <li className={styles.divider} />
    <li>
      <Link href="/settings" className={styles.link}>
        <span className="icon solicon-settings mr-3" />
        <FormattedMessage id="app.settings" defaultMessage="Account settings" />
      </Link>
    </li>
    {/* <li className={styles.divider} />
    <li>
      <a className={`${styles.link} tx-12`} href="https://help.soundonlive.com/" target="_blank">
        <span className="icon solicon-help-alt mr-3" />
        <FormattedMessage id="app.help" defaultMessage="Help" />
      </a>
    </li>
    <li>
      <Link href="/">
        <a className={`${styles.link} tx-12`}>
          <span className="icon solicon-announcement mr-3" />
          <FormattedMessage id="app.sendfeedback" defaultMessage="Send feedback" />
        </a>
      </Link>
    </li> */}
    <li className={styles.divider} />
    <li>
      <button
        type="button"
        className="btn-chromeless tx-left sm"
        onClick={signout}
      >
        <span className="icon solicon-power-off mr-3" />
        <FormattedMessage id="app.signout" defaultMessage="Sign out" />
      </button>
    </li>
  </ul>
)

component.defaultProps = {}

export default component
