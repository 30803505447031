// @flow
import React, { useState, useEffect, useRef } from 'react'
import debounce from 'lodash/debounce'
import styles from './waveform.module.scss'

interface Props {
  src: string
  height?: number
  step?: number
  gap?: number
  position?: number
  innerColor?: string
  outerColor?: string
  cursorColor?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => void
  static?: boolean
  className?: string
  peaks: Array<number>
}

const Wave = ({
  className,
  height,
  cursorColor,
  innerColor,
  outerColor,
  peaks,
  ...props
}: Props) => {
  const wrapRef = useRef(null)
  const canvasRef = useRef(null)
  const wrapWidthRef = useRef(100)
  const [ wrapWidth, setWrapWidth ] = useState(wrapWidthRef.current)
  let cursor

  // methods
  const draw = () => {
    const ctx = canvasRef.current.getContext('2d')

    /* eslint-disable react/destructuring-assignment */
    const step = props.step * 2
    const gap = props.gap * 2
    const width = wrapRef.current.offsetWidth * 2
    // const height = props.height * 2

    const position = width * props.position
    /* eslint-enable react/destructuring-assignment */

    let data = peaks

    const by = data.length / width
    const max = Math.max(...data)

    if (max > 1) {
      data = data.map((i) => i / max)
    }

    ctx.fillStyle = 'transparent'
    ctx.clearRect(0, 0, width, height * 2)
    ctx.fillRect(0, 0, width, height * 2)

    let datum
    for (let i = 0; i < width; i += 1) {
      if (i % (step + gap) >= step) {
        datum = null
        // eslint-disable-next-line no-continue
        continue
      }

      datum = datum || data[Math.ceil(i * by)]

      // if under playing time
      if (position !== 0 && position >= i) {
        // if cursor is under i
        if (cursor >= i && cursor < position) {
          ctx.fillStyle = cursorColor
        }
        else {
          ctx.fillStyle = innerColor
        }
      }
      else if (cursor >= i) {
        ctx.fillStyle = cursorColor
      }
      else {
        ctx.fillStyle = outerColor
      }

      ctx.clearRect(i, height * 2, 1, -1 * datum * height * 2)
      ctx.fillRect(i, height * 2, 1, -1 * datum * height * 2)
    }
  }

  // event callback
  const onResize = debounce(() => {
    if (
      !wrapRef.current ||
      wrapRef.current.offsetWidth === wrapWidthRef.current
    ) {
      return
    }
    wrapWidthRef.current = wrapRef.current.offsetWidth
    setWrapWidth(wrapWidthRef.current)
    draw()
  })

  const onMouseMove = (e) => {
    // eslint-disable-next-line react/destructuring-assignment
    if (props.static) {
      return
    }

    const rect = canvasRef.current.getBoundingClientRect()
    const x = e.clientX - rect.left
    cursor = x * 2
    draw()
  }

  const onMouseLeave = () => {
    cursor = -1
    draw()
  }

  const onMouseUp = (e) => {
    // eslint-disable-next-line react/destructuring-assignment
    if (props.static) {
      return
    }
    const rect = canvasRef.current.getBoundingClientRect()
    const x = e.clientX - rect.left
    // eslint-disable-next-line react/destructuring-assignment
    props.onClick(x / wrapWidth)
  }

  const onTouchEnd = (e) => {
    // if (props.static) { return }
    const rect = canvasRef.current.getBoundingClientRect()
    const touch = e.touches[0] || e.changedTouches[0]
    const x = touch.clientX - rect.left
    // eslint-disable-next-line react/destructuring-assignment
    props.onClick(x / wrapWidth)
  }

  useEffect(() => draw(), [ peaks ])

  useEffect(() => draw())

  // effects
  useEffect(() => {
    window.addEventListener('resize', onResize)
    if (wrapRef.current) {
      setWrapWidth(wrapRef.current.offsetWidth)
    }
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <div ref={wrapRef} className={`${className} ${styles.wrap}`}>
      <style jsx>
        {`
          canvas {
            width: ${wrapWidth}px;
            height: ${height}px;
          }
        `}
      </style>
      <canvas
        ref={canvasRef}
        className={styles.canvas}
        width={wrapWidth * 2}
        height={height * 2}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
      />
    </div>
  )
}

Wave.defaultProps = {
  height: 40,
  step: 2,
  gap: 1,
  position: 0,
  innerColor: '#f24245',
  outerColor: 'rgba(0, 0, 0, .3)',
  cursorColor: 'rgba(242, 66, 62, .6)',
  onClick: () => null,
  static: false,
  className: '',
}

export default Wave
