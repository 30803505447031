import React from 'react'
import classnames from 'classnames'

import styles from './toggle.module.scss'

interface Props {
  className?: string
  on: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => void
  labels?: {
    on: string
    off: string
  }
  disabled?: boolean
}

const Toggle = ({ className, on, onClick, labels, disabled }: Props) => (
  <button
    type="button"
    className={classnames(styles.switch, className, 'tx-12 tx-w600 m-0')}
    onClick={() => onClick(!on)}
    disabled={disabled}
  >
    <div className={classnames(styles.slider, { [styles.active]: on })}>
      <div className={classnames(styles.on, 'flex1')}>{labels.on}</div>
      <div className={classnames(styles.off, 'flex1')}>{labels.off}</div>
    </div>
  </button>
)

Toggle.defaultProps = {
  className: '',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (f: any) => f,
  labels: {
    on: 'ON',
    off: 'OFF',
  },
  disabled: false,
}

export default Toggle
