import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { isProd } from '@/config'

Bugsnag.start({
  apiKey: process.env.BUGSNAG_WWW_KEY || ':)',
  plugins: [ new BugsnagPluginReact(React) ],
  releaseStage: isProd ? 'production' : 'development',
  enabledReleaseStages: [ 'production' ],
})

export default Bugsnag
