// @flow

const pad = (d) => (d < 10 ? `0${d}` : d)

// eslint-disable-next-line import/prefer-default-export
export const timenize = (time: number) => {
  if (!time) {
    return '00:00'
  }

  // eslint-disable-next-line no-bitwise
  const min = (time / 60) << 0
  const sec = Math.round(time % 60)

  return `${pad(min)}:${pad(sec)}`
}

export const htmlEscape = (str: string) =>
  str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&quot;')
    .replace(/'/g, '&#039;')

export const nl2br = (str: string) => htmlEscape(str).replace(/\n/gi, '<br />')
