// @flow
import React, { useState } from 'react'
import Image from 'next/legacy/image'
import { FormattedMessage } from 'react-intl'
import styles from './auth.module.scss'
import Button from '@components/common/button'
import Spinner from '@components/common/spinner'

// import logoSvg from '@static/images/logo-square.svg'


interface Props {
  // signInWithFacebook: () => void
  signInWithTwitter: () => void
  // signInWithGoogle: () => void
  signInWithEmail: (email: string) => void
  toggleSignin: () => void
  isSending: boolean
}

const Sinup = ({
  // signInWithFacebook,
  signInWithTwitter,
  // signInWithGoogle,
  signInWithEmail,
  toggleSignin,
  isSending,
}: Props) => {
  const [ isEmail, setIsEmail ] = useState(false)
  const [ email, setEmail ] = useState('')
  const toggleEmail = () => setIsEmail(!isEmail)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (e: any) => {
    e.preventDefault()
    signInWithEmail(email)
  }

  return (
    <div className={`${styles.container} d-md-flex`}>
      <div
        className={`${styles.hero} d-md-none d-flex justify-content-center align-items-center`}
      >
        <div className={styles.logo}>
          <Image
            src="/static/images/logo-square-white.svg"
            alt="SOUND ON LIVE logo"
            layout="fill"
          />
        </div>
      </div>
      <div
        className={`${styles.form} flex0 d-flex flex-column justify-content-center mr-md-5`}
      >
        <h2 className="tx-center tx-oswald tx-w600 mb-5">
          <FormattedMessage
            id="auth.signup.title"
            defaultMessage="Join SOUND ON LIVE."
          />
        </h2>
        <p className="lead mb-5">
          <FormattedMessage
            id="auth.signup.lead"
            // eslint-disable-next-line max-len
            defaultMessage="Create account to access your personalized homepage, follow artists you love, and clap for sounds, and more."
          />
        </p>
        {!isEmail ? (
          <div className="buttons">
            {/* <button
                type="button"
                aria-label="Signup via Facebook account"
                onClick={signInWithFacebook}
                className={`mb-3 ${styles.facebook}`}
              >
                <i className={`${styles.icon} solicon-facebook`} />
                <FormattedMessage
                  id="auth.signup.facebook"
                  defaultMessage="Sign up with Facebook"
                />
              </button> */}
            <button
              type="button"
              onClick={signInWithTwitter}
              className={`mb-3 ${styles.twitter}`}
            >
              <i className={`${styles.icon} solicon-twitter-alt`} />
              <FormattedMessage
                id="auth.signup.twitter"
                defaultMessage="Sign up with Twitter"
              />
            </button>
            {/* <button
                type="button"
                onClick={signInWithGoogle}
                className={`mb-3 ${styles.google}`}
              >
                <i className={`${styles.icon} solicon-google`} />
                <FormattedMessage
                  id="auth.signup.google"
                  defaultMessage="Sign up with Google"
                />
              </button> */}
            <button
              type="button"
              onClick={toggleEmail}
              className={`${styles.email} mb-4`}
            >
              <i className={`${styles.icon} solicon-email`} />
              <FormattedMessage
                id="auth.signup.email"
                defaultMessage="Sign up with Email"
              />
            </button>
            <div className="tx-center mb-5">
              <button type="button" className="btn-link" onClick={toggleSignin}>
                <FormattedMessage
                  id="auth.signup.toggle"
                  // eslint-disable-next-line max-len
                  defaultMessage="Already have a SOUND ON LIVE account?"
                />
              </button>
            </div>
            <div className="hr" />
            <p className="tx-14">
              <FormattedMessage
                id="signup.agreeterms"
                // eslint-disable-next-line max-len
                defaultMessage="To make SOUND ON LIVE work, we log user data and share it with service providers. Click “Sign up” above to accept our <term>Terms of Service</term> & <privacy>Privacy Policy</privacy>."
                values={{
                  // eslint-disable-next-line max-len, react/display-name, @typescript-eslint/no-explicit-any
                  term: (...chunks: any[]) => (
                    <a
                      href="https://www.soundonlive.com/terms"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {chunks}
                    </a>
                  ),
                  // eslint-disable-next-line max-len, react/display-name, @typescript-eslint/no-explicit-any
                  privacy: (...chunks: any[]) => (
                    <a
                      href="https://www.soundonlive.com/privacy"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </p>
          </div>
        ) : (
          <form onSubmit={onSubmit}>
            <h3 className="tx-oswald tx-w500 tx-18">
              <FormattedMessage
                id="auth.signup.email.title"
                defaultMessage="Sign up with email"
              />
            </h3>
            <p className="help-block">
              <FormattedMessage
                id="auth.signup.email.lead"
                // eslint-disable-next-line max-len
                defaultMessage="Enter the email address, and we’ll send a magic link to your inbox."
              />
            </p>
            <div className="mb-3">
              <input
                type="email"
                name="email"
                className={styles.emailInput}
                value={email}
                placeholder="youremail@example.com"
                onChange={(e) => setEmail(e.target.value)}
                disabled={isSending}
                required
              />
            </div>
            <div className="tx-center mb-5">
              <Button type="submit" disabled={isSending}>
                {isSending && <Spinner size={14} className="mr-2" />}
                <FormattedMessage
                  id="auth.signin.email.continue"
                  defaultMessage="Send me magic link"
                />
              </Button>
            </div>
            <div className="tx-left">
              <button
                type="button"
                className="btn-link tx-14"
                onClick={toggleEmail}
                aria-label="Al signin option"
              >
                <FormattedMessage
                  id="auth.signup.social"
                  defaultMessage="← All sign in option"
                />
              </button>
            </div>
          </form>
        )}
      </div>
      <div
        // eslint-disable-next-line max-len
        className={`${styles.hero} flex1 d-none d-md-flex justify-content-center align-items-center`}
      >
        <Image
          src="/static/images/logo-square-white.svg"
          alt="SOUND ON LIVE logo"
          width={200}
          height={143.75}
        />
      </div>
    </div>
  )
}

Sinup.defaultProps = {}

export default Sinup
