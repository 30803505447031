// @flow
import { AUTH_HOST } from '../../config'
import { get, put, fetchWithErrorHandling } from '@utils/fetch'
import { getToken } from '@utils/token'

export const getMe = async (userToken = '') => {
  const token = userToken || getToken()
  if (!token) {
    return Promise.reject('Not logged in')
  }
  const user = await get('/users/me', { token })
  if (user.error) {
    return Promise.reject(user.error)
  }
  return user
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateMe = async (updates: any) => {
  const user = await put('/users/me', updates)
  return user
}

export const signin = async () => {
  const user = await get('/users/me')
  return user
}

export const usernameAvailable = async (username: string) => {
  const available = await get(`/users/username_available/${username}`)
  return available
}

export const emailAuth = async (email: string) => {
  const body = JSON.stringify({ email })
  // return fetchWithErrorHandling(`${API_HOST}${url}`, { method: 'POST', body, ...options })
  const result = await fetchWithErrorHandling(`${AUTH_HOST}/email/`, {
    method: 'POST',
    body,
  })
  return result
}

export const emailStatus = async (id: string) => {
  const result = await fetchWithErrorHandling(`${AUTH_HOST}/email/status/${id}`)
  return result
}

export const register = async (
  name: string,
  username: string,
  userImage: string,
  email: string,
) => {
  const result = await put('/users/register', {
    name,
    username,
    userImage,
    email,
  })
  return result
}

export const updateEmail = async (email: string, username: string) => {
  const body = JSON.stringify({ email, username })
  const result = await fetchWithErrorHandling(`${AUTH_HOST}/email/update`, {
    method: 'POST',
    body,
  })
  return result
}

export const cancelUpdateEmail = async (token: string) => {
  const result = await fetchWithErrorHandling(
    `${AUTH_HOST}/email/update/cancel/${token}`,
  )
  return result
}
